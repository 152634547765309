import { ReactElement } from 'react';
import { Heading, Icon, Paragraph, Logo } from '@mediahuis/chameleon-react';
import { ExternalLink } from '@mediahuis/chameleon-theme-wl/icons';
import { teaserPremiumIconPosition, breakingTeaserPremiumIconName } from '@hubcms/brand';
import cx from 'classnames';

import { Labels } from '@hubcms/ui-taxonomy';
import { DurationIndicator } from '@hubcms/ui-temporal';
import { ImageElement } from '@hubcms/ui-image';

import { TeaserLink } from '../TeaserLink';
import { PlayButton } from '../PlayButton';

import styles from './breaking-story-block-teaser.module.scss';
import { BreakingStoryBlockTeaserProps } from './types';
import { getDefaultImageDetails, getImageDetails } from '../../utils/getImageDetails';

const BreakingStoryBlockTeaser = ({ data, breakingLabel, imageFormat }: BreakingStoryBlockTeaserProps): ReactElement => {
  const image = data.image ? getImageDetails(data.image, imageFormat) : getDefaultImageDetails(imageFormat);
  const hasImage = image && image.url;

  return (
    <article>
      <TeaserLink className={styles.link} isExternal={data.isExternal} url={data.url} trackingData={data.trackingData}>
        <div className={cx(styles.teaser, { [styles.hasNoImage]: !hasImage })}>
          {hasImage && (
            <>
              <ImageElement
                data-testid="teaser-image"
                className={cx(styles.image, styles.imageContainer)}
                src={image.url}
                alt={image.caption}
                sizes={{ xs: '100vw' }}
                priority={true}
                aspectRatio="sixteenNine"
                layout="cover"
              />
              {['liveVideo', 'video'].includes(data.contentType) && <PlayButton />}
              <div className={styles.imageGradient} />
            </>
          )}
          {breakingLabel && <Paragraph className={styles.breakingLabel}>{breakingLabel}</Paragraph>}
          <div className={cx(styles.titleOverlay, { [styles.hasBreakingLabel]: !!breakingLabel })}>
            {hasImage && (
              <Labels
                isPremium={data.isPremium}
                sublabel={data.subLabel}
                label={data.label}
                icon={data.contentType === 'podcast' ? data.contentType : null}
                premiumIconName={breakingTeaserPremiumIconName}
                premiumIconClassName={styles.premiumIcon}
                className={styles.labels}
                showPremiumIcon={teaserPremiumIconPosition === 'label'}
              />
            )}
            <Heading level={1} data-testid="teaser-heading" className={styles.title}>
              {teaserPremiumIconPosition === 'title' && data.isPremium && (
                <Logo name={breakingTeaserPremiumIconName} className={styles.premiumIcon} />
              )}
              {data.title}
              {data.isExternal && <Icon as={ExternalLink} className={styles.externalLinkIcon} />}
            </Heading>
            {['liveVideo', 'video', 'podcast'].includes(data.contentType) && data.duration && (
              <DurationIndicator className={styles.duration}>{data.duration}</DurationIndicator>
            )}
          </div>
        </div>
      </TeaserLink>
    </article>
  );
};

export default BreakingStoryBlockTeaser;
