import { Icon } from '@mediahuis/chameleon-react';
import { PlayArrow } from '@mediahuis/chameleon-theme-wl/icons';

import styles from './playButton.module.scss';

export default function PlayButton() {
  return (
    <div className={styles.playButton}>
      <Icon as={PlayArrow} size="sm" />
    </div>
  );
}
